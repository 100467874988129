<template>
  <Dialog v-model:visible="localValue" :maximizable="true" :style="{ width: '90%' }" :modal="true" :closable="true">
    <Loader :modelValue="loading"></Loader>
    <template #header>
      <h4>Armar Productos</h4>
    </template>
    <ContextMenu ref="menu" :model="items">
      <template #item="{ item }">
        <a @click="triggerMenu(item)" class="p-menuitem-link" aria-haspopup="false" aria-expanded="true" role="menuitem"
          tabindex="0">
          <i :class="item.icon"></i>
          <span style="padding-left: 1rem;" class="p-menuitem-text">{{ item.label }}</span>
          <span class="p-ink"></span>
        </a>
      </template>
    </ContextMenu>
    <div class="grid p-fluid">
      <div class="col-8">
        <BasicFormToolbar @save="saveDetail" :actions="['save', 'new']" @new="newItem"></BasicFormToolbar>
        <TabView>
          <TabPanel header="Datos Generales">
            <div class="formgrid grid p-fluid">
              <FormInputText label="Padre" v-if="my_product_detail.id_pos_parent"
                v-model="my_product_detail.name_pos_parent" :wrapperClass="'field col-12'" :readonly="true">
              </FormInputText>
              <FormInputText label="Nombre" v-model="my_product_detail.name" :wrapperClass="'field col-12'">
              </FormInputText>
              <FormInputText label="Nivel" :readonly="true" v-model="my_product_detail.step"
                :wrapperClass="'field col-3'"></FormInputText>
              <FormInputNumber label="Minimo Seleccionable" v-model="my_product_detail.min_selectable" showStepper
                :min="0" :max="9" :wrapperClass="'field col-3'"></FormInputNumber>
              <FormInputNumber label="Maximo Seleccionable" v-model="my_product_detail.max_selectable" showStepper
                :min="1" :max="9" :wrapperClass="'field col-3'"></FormInputNumber>
              <FormInputSwitch label="Precio Extra" v-model="my_product_detail.has_extra_price"
                :wrapperClass="'field col-3'"></FormInputSwitch>
            </div>
          </TabPanel>
          <TabPanel header="Productos">
            <Tree :value="productNodes" :key="treeKey" class="w-full md:w-30rem" selectionMode="checkbox"
              v-model:selectionKeys="selectedProducts" :metaKeySelection="false">
            </Tree>
          </TabPanel>
        </TabView>

      </div>
      <div class="col-3">
        <Tree :value="nodes" class="w-full md:w-30rem" selectionMode="single" @node-select="onNodeSelect"
          :metaKeySelection="false"></Tree>
      </div>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text" @click="$emit('closed')" />
      <Button label="Si" icon="pi pi-check" class="p-button-text" @click="$emit('deleted', false)" />
    </template>
  </Dialog>
</template>
  
<script>
import Loader from "../../../components/general/Loader.vue";
import BasicFormToolbar from "../../../components/general/BasicFormToolbar.vue";
import FormInputNumber from "../../../components/general/FormInputNumber.vue";
import FormInputText from "../../../components/general/FormInputText.vue";
import FormInputSwitch from "../../../components/general/FormInputSwitch.vue";
import sessionMixin from "../../../mixins/form";
import { product_detail } from "../../../models/product_detail";
import { fillObject } from '../../../utilities/General';
export default {
  components: { Loader, BasicFormToolbar, FormInputText, FormInputNumber, FormInputSwitch },
  mixins: [sessionMixin],
  props: {
    families: {
      type: Array,
      default: () => []
    },
    products: {
      type: Array,
      default: () => []
    },
    modelValue: {
      required: true,
    },
    id_pos_product: {
      type: Number,
      default: 0
    },
    message: {
      type: String,
      default: '¿Estas seguro de eliminar el registro?'
    }
  },
  data() {
    return {
      loading: false,
      treeKey: "sqweqwwdas",
      localValue: this.modelValue,
      nodes: [],
      productNodes: [],
      selectedProducts: [],
      my_product_detail: new product_detail(),
      my_data: [],
      parent: null,
      selectedNode: null,
      items: [
        {
          label: 'Nuevo hijo',
          icon: 'pi pi-fw pi-plus',
          action: () => this.newChild()
        },
        {
          label: 'Editar',
          icon: 'pi pi-fw pi-pencil',
          action: () => this.editChild()
        },
        {
          label: 'Eliminar',
          icon: 'pi pi-fw pi-trash',
          action: () => this.deleteChild()
        }
      ],
    };
  },
  async mounted() {
    this.my_product_detail = new product_detail(this.session);
    this.my_product_detail.id_pos_product = this.id_pos_product;
    if (this.id_pos_product) {
      await this.my_product_detail.all(this.id_pos_product);
    }
  },
  methods: {
    triggerMenu(event) {
      this.$refs.menu.hide();
      event.action.call();
    },
    newChild() {
      try {
        if ((this.selectedNode.data.detail_products ?? []).length > 0) 
          throw "No se puede crear otro hijo, este elemento contiene productos"
        if ((this.selectedNode.data.step + 1) > 2)
          throw "No se puede crear otro hijo, por el momento el sistema solo admite 2 niveles";
        this.parent = this.selectedNode.data;
        this.my_product_detail.id_pos_product_detail = this.parent.id;
        this.my_product_detail.id_pos_parent = this.parent.id;
        this.my_product_detail.name_pos_parent = this.parent.name;
        this.my_product_detail.step = this.parent.step + 1;
      } catch (error) {
        this.showError(error);
      }
    },
    editChild() {
      //TODO: Aqui debemos convertir el array al tipo objeto seleccionable del tree de productos
      this.parent = null;
      this.my_product_detail = fillObject(this.my_product_detail, this.selectedNode.data);
      if (this.my_product_detail.id_pos_product_detail) {
        this.my_product_detail.id_pos_parent = this.my_product_detail.id_pos_product_detail;
        this.parent = this.my_data.find(x => x.id == this.my_product_detail.id_pos_product_detail);
        this.my_product_detail.name_pos_parent = this.parent.name;
      }
      this.selectedProducts = { };
      this.my_product_detail.detail_products.forEach(product => {
        this.selectedProducts['p-' + product.id_pos_product] = { 
          checked: true,
          partialChecked: false
        }
      });
      this.productNodes.forEach(family => {
        let product_keys = family.children.map(x => { return x.key });
        //? Solo validamos la cantidad de keys seleccionadas para saber si esta completo o no
        let selected_product_keys = Object.keys(this.selectedProducts)
                                          .filter(selected_product_key => product_keys.findIndex(x => x == selected_product_key) >= 0);
        if (selected_product_keys.length > 0) {
          this.selectedProducts[family.key] = { 
            checked: selected_product_keys.length == product_keys.length,
            partialChecked: selected_product_keys.length != product_keys.length
          }
        }
      });
    },
    async deleteChild() {
      try {
        this.my_product_detail = fillObject(this.my_product_detail, this.selectedNode.data);
        if (this.my_product_detail.id) {
          this.loading = true;
          await this.my_product_detail.delete();
          this.showSuccess("El registro se ha eliminado con exito");
          if (this.my_product_detail.step == 2) {
            let parentIndex = this.nodes.findIndex(node => node.key == this.my_product_detail.id_pos_product_detail);
            this.nodes[parentIndex].children = this.nodes[parentIndex].children.filter(node => node.key != this.my_product_detail.id);
          }else {
            this.nodes = this.nodes.filter(node => node.key != this.my_product_detail.id);
          }
          this.newItem();
        }
      } catch (error) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
    onNodeSelect(x) {
      this.selectedNode = x;
      this.$refs.menu.show(event);
    },
    newItem() {
      this.my_product_detail = new product_detail(this.session);
      this.parent = null;
      this.my_product_detail.id_pos_product_detail = null;
      this.my_product_detail.id_pos_parent = null
      this.my_product_detail.name_pos_parent = null;
      this.selectedProducts = {};
    },
    async saveDetail() {
      this.loading = true;
      try {
        let productKeys = Object.keys(this.selectedProducts)
                                .filter(property => property.split('-')[0] == "p")
                                .map(property => { return parseInt(property.split('-')[1]) });
        this.my_product_detail.products = productKeys;
        this.my_product_detail.id_pos_product = this.id_pos_product;
        if (this.my_product_detail.id == null) {
          let savedProductDetail = await this.my_product_detail.save();
          this.my_product_detail.id = savedProductDetail.id;
          this.my_data.push(this.my_product_detail);
          if (savedProductDetail.step == 1) {
            this.nodes.push({
              key: savedProductDetail.id,
              label: this.my_product_detail.name,
              data: this.my_product_detail,
              icon: 'pi pi-fw pi-inbox'
            });
          }else if (savedProductDetail.step == 2) {
            let index = this.nodes.findIndex(x => x.key == savedProductDetail.id_pos_product_detail);
            console.log('index', index);
            console.log('node', this.nodes[index]);

            if (!this.nodes[index].children)
            {
              this.nodes[index].children = [];
            }
              
            this.nodes[index].children.push({
              key: savedProductDetail.id,
              label: this.my_product_detail.name,
              data: this.my_product_detail,
              icon: 'pi pi-fw pi-inbox'
            });
          }
          this.newItem();
          this.showSuccess("El registro se ha guardado con exito");
        }else {
          let updatedProductDetail = await this.my_product_detail.update();
          this.my_data[this.my_data.findIndex(x => x.id == this.my_product_detail.id)] = this.my_product_detail;
          this.nodes[this.nodes.findIndex(x => x.key == updatedProductDetail.id)] = {
            key: updatedProductDetail.id,
            label: this.my_product_detail.name,
            data: this.my_product_detail,
            icon: 'pi pi-fw pi-inbox'
          };
          this.newItem();
          this.showSuccess("El registro se ha actualizado con exito");
        }
        this.newItem();
      } catch (error) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    ["my_product_detail.min_selectable"](newValue) {
      this.my_product_detail.max_selectable = newValue > this.my_product_detail.max_selectable ? newValue : this.my_product_detail.max_selectable;
    },
    ["my_product_detail.max_selectable"](newValue) {
      this.my_product_detail.min_selectable = newValue < this.my_product_detail.min_selectable ? newValue : this.my_product_detail.min_selectable;
    },
    async localValue(newValue) {
      if (newValue) {
        if (this.id_pos_product) {
          let details = await this.my_product_detail.all(this.id_pos_product);
          this.nodes = [];
          details.forEach(detail => {
            if (detail.step == 1) {
              let children = details.filter(x => x.step == 2 && x.id_pos_product_detail == detail.id)
                .map(x => {
                  return  {
                    key: x.id,
                    label: x.name,
                    data: x,
                    icon: 'pi pi-fw pi-shopping-bag',
                  }
                });
              this.nodes.push({
                key: detail.id,
                label: detail.name,
                data: detail,
                icon: 'pi pi-fw pi-shopping-bag',
                children: children
              });
            }
          });
          this.productNodes = [];
          this.families.forEach(family => {
            let familyNode = {
              key: 'f-' + family.id,
              label: family.name,
              data: family,
              icon: 'pi pi-fw pi-shopping-bag'
            };
            familyNode.children = this.products.filter(x => x.id_pos_family == family.id).map(x => {
              return {
                key: 'p-' + x.id,
                label: x.name,
                data: x,
                icon: 'pi pi-fw pi-shopping-bag'
              }
            })
            if (familyNode.children.length > 0)
              this.productNodes.push(familyNode);
          });
        }
      }
      this.$emit('update:modelValue', newValue);
    },
    modelValue(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>
  
<style></style>
  