<template>
  <div class="grid formgrid p-fluid">
    <div class="col-12">
        <Loader v-model="loading" />
        <ProductoCompuesto v-model="productoCompuestoModal" :products="products" :families="families" :id_pos_product="product.id" />
        <Panel header="Producto">
          <TabView>
              <TabPanel header="General">
                <div class="formgrid grid p-fluid">
            <div class="col-12">
              <FormToolbar :actions="['new','save','delete']" @new="newProduct" @save="saveProduct" @delete="deleteProduct" />
            </div>
            <div :class="{
              'col-9': product.id,
              'col-12': product.id == null,
              'formgrid' : true,
              'grid' : true,
              'p-fluid': true,
            }">
              <FormInputText wrapperClass="field col-12 xl:col-3" label="Clave" v-model="product.key_name" />
            <FormInputText wrapperClass="field col-12 xl:col-9" label="Nombre" v-model="product.name" />
            <FormDropdown wrapperClass="field col-12 xl:col-3" label="Familia" v-model="product.id_pos_family" :options="families" optionLabel="name" optionValue="id" />
            <FormInputNumber wrapperClass="field col-12 xl:col-3" label="Precio" v-model="product.price" :mode="'currency'"
            :minFractionDigits="2" :maxFractionDigits="2" />
            <FormInputNumber wrapperClass="field col-12 xl:col-3" label="Costo" v-model="product.cost" :mode="'currency'"
            :minFractionDigits="2" :maxFractionDigits="2" />
            <FormDropdown wrapperClass="field col-12 xl:col-3" label="IVA" v-model="product.iva_percentage" :options="[
                  { label: '%0.00', value: 0 },
                  { label: '%16.00', value: 16 },
                ]" optionLabel="label" optionValue="value" />
            <FormInputText wrapperClass="field col-12 xl:col-3" label="Codigo de Barras" v-model="product.codebar" />
            <Autocomplete wrapperClass="field col-12 xl:col-3" label="Codigo SAT" :items="c_ClaveProdServ" v-model="product.sat_code" />
            <Autocomplete wrapperClass="field col-12 xl:col-3" label="Unidad Medida SAT" :items="c_ClaveUnidad" v-model="product.sat_measure_unit" />
            <FormInputSwitch wrapperClass="field col-12 xl:col-1" label="Activo" v-model="product.is_active"></FormInputSwitch> 
            <div v-if="product.id" class="field col-12 xl:col-2">
              <label for="">Imagen</label>
              <FileUpload @select="selectFile" style="width: 100%" mode="basic" :url="uploadUrl" accept="image/*" :maxFileSize="1000000" @upload="onUpload" :multiple="false" />
            </div>
            </div>
            <div class="col-3" v-if="product.id">
              <Image :src="product.image_mostrar ?? 'images/tpv/notfound.png'" alt="Image" :imageStyle="'max-width: 20rem; max-height: 15rem'" preview />
            </div>
            <Divider></Divider>
            <div class="col">
              <Button v-if="product.id" label="Agregar producto / servicio compuesto" @click="productoCompuestoModal = true" ></Button>
            </div>
          </div>
              </TabPanel>
              <TabPanel header="Precios">
                <div class="formgrid grid p-fluid">
                  <div class="col-12">
                    <FormToolbar :actions="['new','save']" @new="newProduct" @save="saveListProduct" />
                  </div>
                  <div :class="{
                    'col-12': true,
                    'formgrid' : true,
                    'grid' : true,
                    'p-fluid': true,
                  }">
                  <FormDropdown wrapperClass="field col-12 xl:col-6" label="Lista de Precios" v-model="my_price_list_product.id_pos_price_list" :options="list_prices" optionLabel="name" optionValue="id" />
                  <FormInputNumber wrapperClass="field col-12 xl:col-6" label="Precio" v-model="my_price_list_product.price" :mode="'currency'"
                  :minFractionDigits="2" :maxFractionDigits="2" />
                  <div class="col-12">
                    <BasicDatatable
                    :footerVisible="false"
                    :headerVisible="false"
                    :rowsFilter="false"
                    :rows="myListPrices"
                    :dataKey="'name'"
                    :rowaction="true"
                    :rowdelete="true"
                    :selectionMode="'single'"
                    :headers="headersListPrices"
                    @selected="selectListPrices"
                    @deleted="deletePrice"
                  />
                  </div>
                  </div>
                </div>
              </TabPanel>
          </TabView>
        </Panel>
    </div>
    <div class="col-12">
        <BasicDatatable
          :contextMenu="true" 
          :menuModel="[
              {
                label: 'Generar Producto Compuesto', icon: 'pi pi-fw pi-shopping-cart', action: 'producto-compuesto'
              }
          ]"
          @producto-compuesto="productoCompuestoModal = true"
          :rows="products"
          :selectionMode="'single'"
          :headers="headers"
          @selected="selectProduct"
        />
    </div>
  </div>
</template>
<script>
import FormToolbar from "../../../components/general/BasicFormToolbar.vue";
import FormInputText from "../../../components/general/FormInputText.vue";
import FormInputNumber from "../../../components/general/FormInputNumber.vue";
import FormInputSwitch from "../../../components/general/FormInputSwitch.vue";


import Autocomplete from "../../../components/general/Autocomplete.vue";

import FormDropdown from "../../../components/general/FormDropdown.vue";
import formMixin from '../../../mixins/form.js';

import { product } from "../../../models/product";
import { family } from "../../../models/family";
import { price_list } from "../../../models/price_list";
import { price_list_product } from "../../../models/price_list_product";

import Loader from "../../../components/general/Loader.vue";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import { fillObject, HeaderGrid, satCatalogo, toBase64 } from "../../../utilities/General";
import ProductoCompuesto from "./ProductoCompuesto.vue";
export default {
  mixins: [formMixin],
  expose: ["refresh"],
  components: { ProductoCompuesto, FormInputText, Autocomplete, FormInputNumber, FormInputSwitch, FormDropdown, FormToolbar, Loader, BasicDatatable },
  data() {
    return {
      productoCompuestoModal:false,
      loading: false,
      product: new product(),
      my_price_list_product: new price_list_product(),
      list_prices: [],
      products: [],
      families: [],
      c_ClaveUnidad: [],
      c_ClaveProdServ: [],
      headers: [
        new HeaderGrid("Clave", "key_name"),
        new HeaderGrid("Nombre", "name"),
      ],
      headersListPrices: [
          new HeaderGrid("Lista", "name"),
          new HeaderGrid("Precio", "price", {type: 'currency'}),
       ]
    };
  },
  computed: {
    uploadUrl() {
      return this.$config.api_route + "tpv/products/image/" + this.product.id;
    },
    myListPrices() {
      return this.product.list_prices.filter(x => x.deleted != true);
    }
  },
  methods: {
    async deleteProduct() {
      this.loading = true;
      try {
        if (this.product.id) {
          await this.product.delete();
          this.products = this.products.filter(x => x.id != this.product.id);
          this.newProduct();
          this.showSuccess("Se ha eliminado el producto");
        }
      } catch (error) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
    deletePrice(payload) {
      this.product.list_prices.find(x => x.name == payload.name).deleted = true;
    },
    selectListPrices(payload) {
      this.my_price_list_product = fillObject(this.my_price_list_product, payload);
    },
    saveListProduct() {
      //Si ya existe el precio solo cambiamos
      let list_price = this.product.list_prices.find(x => x.id_pos_price_list == this.my_price_list_product.id_pos_price_list);
      if (list_price) {
        list_price.price = this.my_price_list_product.price
      }else {
        this.product.list_prices.push({name: this.list_prices.find(x => x.id == this.my_price_list_product.id_pos_price_list).name,... this.my_price_list_product});
      }
      this.my_price_list_product = new price_list_product();
    },
    async selectFile(payload) {
      let base64 = await toBase64(payload.files[0]);
      console.log(base64);
      this.product.image_mostrar = base64;  
    },
    async onUpload(payload) {
      this.product.image = payload.image;
      this.showSuccess("Imagen cargada con exito");
    },
    selectProduct(entity) {
      this.product = fillObject(this.product, entity);
      this.product.image_mostrar = this.product.image == null ? null : this.$config.api_route + "images/products/" + this.product.image;
      this.product.list_prices = this.product.list_prices.map(x => {
        return {
          name: this.list_prices.find(y => y.id == x.id_pos_price_list).name,
          ...x
        }
      });
      console.log('product', this.product)
    },
    newProduct() {
      this.product = new product(this.session);
    },
    async refresh() {
    this.loading = true;
    this.product = new product(this.session);
    try {
      this.products = await this.product.all();
      this.families = await new family(this.session).all();
      this.list_prices = await new price_list(this.session).all();
      this.c_ClaveUnidad = (await satCatalogo(16)).map(x => x.c_ClaveUnidad);
      this.c_ClaveProdServ = (await satCatalogo(1)).map(x => x.id);
    } catch (error) {
      this.showError(error);
    } finally {
      this.loading = false;
    }
  },
    async saveProduct() {
      this.loading = true;
      try {
        if (this.product.id) {
          let editedProduct = await this.product.update();
          this.products[this.products.findIndex(x => x.id == this.product.id)] = editedProduct;
        }else {
          var newProduct = await this.product.save();
          this.products.push(newProduct);
        }
        this.newProduct();
      } catch (error) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
  },

};
</script>

<style>
</style>

