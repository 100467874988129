import axios from 'axios';
export class product_detail
{
    constructor(session) {
        this.user = session ? session.user : null;
        this.id_company = session ? session.id_company : null;
        this.id_branch = session ? session.id_branch : null;
        this.token = session ? session.token : null;
        this.id = null;
        this.id_pos_product = null;
        this.id_pos_product_detail = null;
        this.name = null;
        this.step = 1;
        this.min_selectable = 1;
        this.max_selectable = 1;
        this.has_extra_price = false;
        this.products = [];
        this.created_by = null;
        this.created = null;
        this.modified_by = null;
        this.modified = null;
    }  

    async all(id_pos_product) {
        let response = await axios.get("tpv/productdetail/" + id_pos_product, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }


    async save() {
        let response = await axios.post("tpv/productdetail", this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }

    async update() {
        let response = await axios.put("tpv/productdetail/" + this.id, this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }

    async delete() {
        let response = await axios.delete("tpv/productdetail/" + this.id, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }
}